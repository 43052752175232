<template>
     <div>
             <!-- 面包屑区域 -->
            <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

             <!-- 卡片视图区域 -->
             <el-card shadow="hover">
                 <div class="invite">
                        <Title :title="title[0]"></Title>
                        <div class="content">
                              <p class="tip">复制链接发送邀请给好友</p>
                              <div class="main">
                                  <div class="link">{{invitationInfo.url}}</div>
                                  <!-- <el-button type="primary" @click="copylink"  v-clipboard:copy="link" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</el-button> -->
                                  <el-button type="primary" v-clipboard:copy="invitationInfo.url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</el-button>
                              </div>
                              <p class="zs">{{invitationInfo.txt}}</p>
                        </div>
                 </div>
             </el-card>
     </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            title: ["邀请好友"],
            //面包屑数据
            breadcrumbData:[ 
                {
                    id:1,
                    title:'好物电商',
                    path:'',
                    isClick:false,
                },
                {
                     id:2,
                     title:'邀请好友',
                     path:'invitefriends',
                     isClick:true,
                }
            ],
            // link:'http://zc.rsk.cn/#/register?incode=yIt6Z2'
        }
    },
    computed:{
        ...mapState({
            invitationInfo:state => state.invitation.invitationInfo
        })
    },
    methods:{
        //获取邀请信息
        getInvitationInfo(){
            this.$store.dispatch('getInvitationInfo')
        },
        //复制链接 
        //方式一
        // copylink(){
        //       this.$copyText(this.link).then((e) =>{
        //              this.$message.success('复制成功!')
        //        }, (e) => {
        //              this.$message.error('复制失败!')
        //       })
        // },

        //方式二
        onCopy(e) {
             this.$message.success('复制成功!')
        },
        onError(e){
             this.$message.error('复制失败!')
        }
    },
    mounted(){
        this.getInvitationInfo()
    }
}
</script>

<style lang="less" scoped>
.el-card {
  margin: 20px 20px 50px 20px;
}
.invite{
     .content{
         margin-top:20px;
         .tip{
             font-size:22px;
             font-weight:500;
         }
         .main{
             margin-top:20px;
             display: flex;
             justify-content:flex-start;
             align-items: center;
             .link{
                     border:1px solid #409EFF;
                     margin-right:20px;
                     padding:10px 30px;
                     border-radius:5px;
                     font-size:16px;
             }
         }
         .zs{
             color:#D40B35;
             margin-top:20px;
             margin-bottom:80px;
         }
     }
}
</style>